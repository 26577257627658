import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { FaBookmark, FaRegBookmark } from "react-icons/fa6";
import { IoPeople, IoPerson, IoTicket, IoCheckmark } from "react-icons/io5";
import { RxMixerHorizontal } from "react-icons/rx";
import { IoClose } from "react-icons/io5";
import '../UserList.css';
import MobileProfileNavMenu from '../gizmos/MobileProfileNavMenu';
import MobileWatchedTitles from './titleviews/MobileWatchedTitles';
import '../../App.css';
import { compareDesc } from 'date-fns'

const SORT_OPTIONS = [
  { value: 'added_at', label: 'Date Added' },
  { value: 'release_date_desc', label: 'Release Date (Newest)' },
  { value: 'release_date_asc', label: 'Release Date (Oldest)' },
  { value: 'title_asc', label: 'Title (A-Z)' },
  { value: 'title_desc', label: 'Title (Z-A)' },
  { value: 'vote_average', label: 'Rating' }
];

const sortUserList = (userList, sortBy) => {
  return [...userList].sort((a, b) => {
    switch (sortBy) {
      case 'added_at':
        return new Date(b.added_at) - new Date(a.added_at);
      
      case 'release_date_desc':
        // If release_date is missing, treat as most recent
        if (!a.movieDetails?.release_date) return -1;
        if (!b.movieDetails?.release_date) return 1;
        
        const dateADesc = new Date(a.movieDetails.release_date);
        const dateBDesc = new Date(b.movieDetails.release_date);
        
        return compareDesc(dateADesc, dateBDesc);

      case 'release_date_asc':
        // If release_date is missing, treat as most recent
        if (!a.movieDetails?.release_date) return 1;
        if (!b.movieDetails?.release_date) return -1;
        
        const dateAAsc = new Date(a.movieDetails.release_date);
        const dateBAsc = new Date(b.movieDetails.release_date);
        
        return compareDesc(dateBAsc, dateAAsc); // Reverse the comparison
      
      case 'title_asc':
        const titleAAsc = a.movieDetails?.title?.toLowerCase() || '';
        const titleBAsc = b.movieDetails?.title?.toLowerCase() || '';
        return titleAAsc.localeCompare(titleBAsc);
      
      case 'title_desc':
        const titleADesc = a.movieDetails?.title?.toLowerCase() || '';
        const titleBDesc = b.movieDetails?.title?.toLowerCase() || '';
        return titleBDesc.localeCompare(titleADesc);
      
      case 'vote_average':
        const ratingA = a.movieDetails?.vote_average || 0;
        const ratingB = b.movieDetails?.vote_average || 0;
        return ratingB - ratingA;
      
      default:
        return new Date(b.added_at) - new Date(a.added_at);
    }
  });
};

const sortWatchedMovies = (watchedMovies) => {
  return [...watchedMovies].sort((a, b) => new Date(b.watchDate) - new Date(a.watchDate));
};

export default function MobileUserList({ 
  userList, 
  watchedMovies, 
  onToggleBookmark, 
  onToggleWatched, 
  activeSection, 
  setActiveSection, 
  isInWatchlist,
  isMovieWatched,
  isMovieReleased
}) {
    const [selectedSortBy, setSelectedSortBy] = useState('added_at');
    const [selectedPlatforms, setSelectedPlatforms] = useState([]);
    const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
    const [isStreamingNow, setIsStreamingNow] = useState(false);

    const availablePlatforms = useMemo(() => {
      const platforms = new Set();
      userList.forEach(item => {
        const providers = item.movieDetails.watch_providers;
        if (providers && providers.stream) {
          providers.stream.forEach(provider => platforms.add(provider.provider_name));
        }
      });
      return Array.from(platforms);
    }, [userList]);

    const filteredUserList = useMemo(() => {
      if (selectedPlatforms.length === 0) return userList;
      return userList.filter(item => {
        const providers = item.movieDetails.watch_providers;
        if (selectedPlatforms.includes('Not Available')) {
          return !providers || !providers.stream || providers.stream.length === 0;
        }
        if (!providers || !providers.stream) return false;
        return providers.stream.some(provider => selectedPlatforms.includes(provider.provider_name));
      });
    }, [userList, selectedPlatforms]);

    const sortedList = useMemo(() => 
      sortUserList(filteredUserList, selectedSortBy),
      [filteredUserList, selectedSortBy]
    );

    const sortedWatchedMovies = sortWatchedMovies(watchedMovies);

    const handlePlatformChange = (platform) => {
      setSelectedPlatforms(prev => {
        if (platform === 'Not Available') {
          return prev.includes('Not Available') ? [] : ['Not Available'];
        } else {
          const newSelection = prev.filter(p => p !== 'Not Available');
          if (newSelection.includes(platform)) {
            return newSelection.filter(p => p !== platform);
          } else {
            return [...newSelection, platform];
          }
        }
      });
    };

    const handleClearFilter = () => {
      setSelectedPlatforms([]);
      setIsStreamingNow(false);
    };

    const handleSelectAllFilter = () => {
      setSelectedPlatforms([...availablePlatforms]);
      setIsStreamingNow(true);
    };

    const handleStreamingNowChange = () => {
      const currentState = Boolean(isStreamingNow);
      setIsStreamingNow(!currentState);
      if (currentState) {
        handleClearFilter();
      } else {
        handleSelectAllFilter();
      }
    };

    const handleBookmarkClick = (e, titleId) => {
      e.preventDefault();
      e.stopPropagation();
      onToggleBookmark(titleId);
    };
  
    const sectionDisplayNames = [
      { key: 'watchlist', name: 'Watchlist', nav_id: 'watchlist', nav_display_name: 'Watchlist' },
      { key: 'watched', name: 'Watched', nav_id: 'watched', nav_display_name: 'Watched' },
    ];
  
    const handleSortChange = (sortValue) => {
      setSelectedSortBy(sortValue);
    };
  
    return (
      <div className="user-list-mobile">
        <nav className="user-profile-nav-mobile" aria-label="User list sections">
          <MobileProfileNavMenu 
            items={sectionDisplayNames} 
            click={setActiveSection} 
            setActiveSection={setActiveSection} 
            activeSection={activeSection} 
          />
        </nav>
        {activeSection === 'watchlist' ? (
          <>
            <div className="mobile-filter-header">
              <button 
                className="mobile-filter-toggle"
                onClick={() => setIsFilterDrawerOpen(true)}
              >
                <RxMixerHorizontal /> Watchlist Options
                {selectedPlatforms.length > 0 && (
                  <span className="mobile-filter-count">{selectedPlatforms.length}</span>
                )}
              </button>
              {selectedPlatforms.length > 0 && (
                <button className="mobile-filter-clear" onClick={handleClearFilter}>
                  Clear filters
                </button>
              )}
            </div>

            {isFilterDrawerOpen && (
              <div className="mobile-filter-overlay" onClick={() => setIsFilterDrawerOpen(false)}>
                <div 
                  className="mobile-filter-side-panel"
                  onClick={e => e.stopPropagation()}
                >
                  <div className="mobile-filter-panel-header">
                    <h3>Watchlist Options</h3>
                    <button 
                      className="mobile-filter-panel-close"
                      onClick={() => setIsFilterDrawerOpen(false)}
                    >
                      <IoClose />
                    </button>
                  </div>

                  <div className="mobile-filter-panel-content">
                    <div className="mobile-filter-section">
                      <h4 className="mobile-filter-section-title">Sort By</h4>
                      <div className="mobile-filter-sort-options">
                        {SORT_OPTIONS.map(option => (
                          <label key={option.value} className="mobile-filter-sort-item">
                            <input
                              type="radio"
                              name="sort-by"
                              value={option.value}
                              checked={selectedSortBy === option.value}
                              onChange={() => handleSortChange(option.value)}
                            />
                            <span>{option.label}</span>
                          </label>
                        ))}
                      </div>
                    </div>

                    <hr className="mobile-filter-divider" />

                    <div className="mobile-filter-section">
                      <h4 className="mobile-filter-section-title">Streaming Options</h4>
                      <div className="mobile-filter-streaming-toggle" onClick={handleStreamingNowChange}>
                        <input
                          type="checkbox"
                          checked={isStreamingNow}
                          onChange={handleStreamingNowChange}
                        />
                        <span>Streaming only</span>
                      </div>

                      <div className="mobile-filter-platforms">
                        {availablePlatforms.map(platform => (
                          <label key={platform} className="mobile-filter-platform-item">
                            <input
                              type="checkbox"
                              checked={selectedPlatforms.includes(platform)}
                              onChange={() => handlePlatformChange(platform)}
                            />
                            <span>{platform}</span>
                          </label>
                        ))}
                        <label className="mobile-filter-platform-item">
                          <input
                            type="checkbox"
                            checked={selectedPlatforms.includes('Not Available')}
                            onChange={() => handlePlatformChange('Not Available')}
                          />
                          <span>Not Available for Streaming</span>
                        </label>
                      </div>
                    </div>

                  </div>

                  <div className="mobile-filter-panel-footer">
                    <button onClick={handleClearFilter}>Clear All</button>
                    <button onClick={() => setIsFilterDrawerOpen(false)}>Apply</button>
                  </div>
                </div>
              </div>
            )}

            <div className="user-list-items">
              {sortedList.map((item) => (
                <Link to={`/movie/${item.title_id}`} key={item.title_id} className="user-list-item">
                    <div 
                      className="user-list-item-bookmark"
                      onClick={(e) => handleBookmarkClick(e, item.title_id)}
                    >
                      {isInWatchlist(item.title_id) ? <FaBookmark /> : <FaRegBookmark className="user-list-item-bookmark-add" />}
                    </div>
                  <div className="user-list-item-poster">
                    {item.movieDetails && item.movieDetails.poster_path ? (
                      <img 
                        src={`https://image.tmdb.org/t/p/w92${item.movieDetails.poster_path}`} 
                        alt={item.movieDetails.title} 
                        className="user-list-item-poster-img"
                      />
                    ) : (
                      <div className="user-list-item-poster-placeholder">No Image</div>
                    )}
                  </div>
                  <div className="user-list-item-info">
                    <div>
                      <div className="user-list-item-title-row">
                        <h3 className="user-list-item-title">{item.movieDetails ? item.movieDetails.title : 'Unknown Title'}</h3>
                      </div>
                      <div className="user-list-item-info-row">
                        {item.movieDetails && (
                          <p className="user-list-item-release-date">
                            {item.movieDetails.release_date 
                              ? new Date(item.movieDetails.release_date).getFullYear() 
                              : "No release date"}
                          </p>
                        )}
                        <div className="user-list-item-ratings">
                          {item.averageUserRating != null && (
                            <p className="user-list-item-rating">
                              <IoPeople /> {item.averageUserRating.toFixed(1)}
                            </p>
                          )}
                          {item.userRating != null && (
                            <p className="user-list-item-rating">
                              <IoPerson /> {item.userRating.toFixed(1)}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="user-list-item-actions">
                      <div 
                        className={`user-list-item-watched ${!isMovieReleased(item.movieDetails?.release_date) ? 'disabled' : ''}`}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          if (isMovieReleased(item.movieDetails?.release_date)) {
                            onToggleWatched(item.title_id);
                          }
                        }}
                      >
                        <IoTicket />
                      </div>
                    </div>
                    <p className="user-list-item-added">Added: {new Date(item.added_at).toLocaleDateString()}</p>
                  </div>
                  {isMovieReleased(item.movieDetails?.release_date) && (
                    isMovieWatched(item.title_id) ? (
                      <div className="user-list-item-watched-indicator">
                        <IoCheckmark />
                      </div>
                    ) : (
                      <div 
                        className="user-list-item-watch"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onToggleWatched(item.title_id);
                        }}
                      >
                        <IoTicket />
                      </div>
                    )
                  )}
                </Link>
              ))}
              {sortedList.length === 0 &&
              <div className="user-list-empty">
                <p>Your watchlist is empty.</p>
                <p>Add some movies to get started.</p>
              </div>
              }
            </div>
          </>
        ) : (
          <MobileWatchedTitles watchedMovies={sortedWatchedMovies} />
        )}
      </div>
    );
  };
