import React, { useState, useCallback } from 'react';
import moment from 'moment';
import ReleaseCalendar from './ReleaseCalendar'
import { getHorrorReleases } from '../../api';
import './ReleaseCalendar.css'

export default function ReleaseCalendarPage() {
    const [calendarMovieCache, setCalendarMovieCache] = useState({});
    const [currentMonth, setCurrentMonth] = useState(moment().startOf('month'));
    const fetchMoviesForMonth = useCallback(async (year, month) => {
        const cacheKey = `${year}-${month}`;
        if (calendarMovieCache[cacheKey]) {
          return calendarMovieCache[cacheKey];
        }
    
        try {
          const response = await getHorrorReleases(year, month);
          const newMovies = response.data;
          setCalendarMovieCache(prevCache => ({
            ...prevCache,
            [cacheKey]: newMovies,
          }));
          return newMovies;
        } catch (error) {
          console.error('Error fetching horror movies:', error);
          return [];
        }
      }, [calendarMovieCache]);
    
      const handleMonthChange = useCallback((newMonth) => {
        setCurrentMonth(newMonth);
      }, []);
    
    return (
        <div>
            <h2>Release Calendar</h2>
            <ReleaseCalendar
                movieCache={calendarMovieCache}
                fetchMoviesForMonth={fetchMoviesForMonth}
                currentMonth={currentMonth}
                onMonthChange={handleMonthChange}
            />
        </div>
    )
}
