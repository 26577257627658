import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';
import Header from './components/header/Header';
import Footer from './components/Footer';
import Home from './components/home/Home';
import ErrorBoundary from './components/ErrorBoundary';
import MovieDetails from './components/MovieDetails';
import MoviesByTag from './components/MoviesByTag';
import Registration from './components/Registration';
import ReleaseCalendarPage from './components/releasecalendar/ReleaseCalendarPage';
import Login from './components/Login';
import Profile from './components/Profile';
import ResetPassword from './components/ResetPassword';
import ForgotPassword from './components/ForgotPassword';
import SearchResults from './components/SearchResults';
import MobileNavigation from './components/MobileNavigation';
import UserListManager from './components/UserListManager';
import FeaturedKeyword from './components/keywords/FeaturedKeyword';
import MoviesByProvider from './components/moviesbyprovider/MoviesByProvider';
import ReviewPage from './components/reviews/ReviewPage';
import './App.css';
import { WatchlistProvider } from './context/WatchlistContext';
import ProfessionalIdentity from './components/personalities/ProfessionalIdentity';

export default function App() {
  function HomePage() {
    const { isAuthenticated, loading } = useAuth();
    if (loading) return <div>Loading...</div>
    if (!isAuthenticated) return <Home/>
    return <Home/>
  }
  function PrivateRoute({ children }) {
    const { isAuthenticated, loading } = useAuth();
    if (loading) {
      return <div>Loading...</div>;
    }
    return isAuthenticated ? children : <Navigate to="/login" />;
  }
  return (
    <AuthProvider>
      <WatchlistProvider>
        <Router>
          <div className="App">
            <Header />
            <main>
              <ErrorBoundary>
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/movie/:id" element={<MovieDetails />} />
                  <Route path="/movie/:id/review" element={<ReviewPage />} />
                  <Route path="/tag/:tag" element={<MoviesByTag />} />
                  <Route path="/create-account" element={<Registration />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/calendar" element={<ReleaseCalendarPage />} />
                  <Route 
                    path="/profile" 
                    element={
                      <PrivateRoute>
                        <Profile />
                      </PrivateRoute>
                    } 
                  />
                  <Route 
                    path="/list" 
                    element={
                      <PrivateRoute>
                        <UserListManager />
                      </PrivateRoute>
                    } 
                  />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/search" element={<SearchResults />} />
                  <Route path="/keyword/:keyword" element={<FeaturedKeyword />} />
                  <Route path="/provider/:keyword" element={<MoviesByProvider />} />
                  <Route path="*" element={<Navigate to="/" replace />} />
                  <Route path="/person/:id" element={<ProfessionalIdentity />} />
                </Routes>
              </ErrorBoundary>
            </main>
            <Footer />
            <MobileNavigation />
          </div>
        </Router>
      </WatchlistProvider>
    </AuthProvider>
  );
}