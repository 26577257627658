import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import api from '../../api';
import './UserRatings.css';
import { AiFillStar } from 'react-icons/ai';

export default function UserRatings() {
    const [ratings, setRatings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [cachedRatings, setCachedRatings] = useState({});

    const fetchUserRatings = useCallback(async (page) => {
        if (cachedRatings[page]) {
            setRatings(cachedRatings[page]);
            return;
        }

        try {
            setLoading(true);
            const response = await api.get(`/ratings/user?page=${page}`);
            setRatings(response.data.ratings);
            console.log(response.data.ratings[0])
            setTotalPages(response.data.totalPages);
            setCurrentPage(response.data.currentPage);
            setCachedRatings(prev => ({ ...prev, [page]: response.data.ratings }));
            setLoading(false);
        } catch (err) {
            console.error('Error fetching user ratings:', err);
            setError(`Failed to fetch ratings. ${err.response?.data?.message || err.message}`);
            setLoading(false);
        }
    }, [cachedRatings]);

    useEffect(() => {
        fetchUserRatings(currentPage);
    }, [currentPage, fetchUserRatings]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const formatDate = (dateString) => {
        const formattedDate = new Date(dateString).toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric'
        });
        return `Last rated ${formattedDate}`;
    };

    const formatRuntime = (minutes) => {
        if (!minutes) return null;
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        return `${hours}hr ${remainingMinutes}m`;
    };

    if (loading) {
        return <div className="user-ratings-loading">Loading...</div>;
    }

    if (error) {
        return <div className="user-ratings-error">{error}</div>;
    }

    return (
        <div className="user-ratings-container">
            {ratings.length > 0 ? (
                <>
                    <div className="user-ratings-list">
                        {ratings.map((rating, index) => (
                            <React.Fragment key={rating._id}>
                                {index > 0 && <hr className="user-ratings-divider" />}
                                <div className="user-ratings-item">
                                    <Link 
                                        to={`/movie/${rating.movieId}`} 
                                        className="user-ratings-poster-link"
                                    >
                                        <div className="user-ratings-poster">
                                            {rating.posterPath ? (
                                                <img 
                                                    src={`https://image.tmdb.org/t/p/w92${rating.posterPath}`} 
                                                    alt={rating.movieTitle} 
                                                    className="user-ratings-poster-image"
                                                    loading="lazy"
                                                />
                                            ) : (
                                                <div className="user-ratings-poster-placeholder">
                                                    No Image
                                                </div>
                                            )}
                                        </div>
                                    </Link>
                                    <div className="user-ratings-content">
                                        <div className="user-ratings-date">
                                            {formatDate(rating.updatedAt)}
                                        </div>
                                        <Link 
                                            to={`/movie/${rating.movieId}`}
                                            className="user-ratings-title-link"
                                        >
                                            <h3 className="user-ratings-title">{rating.movieTitle}</h3>
                                        </Link>
                                        <div className="user-ratings-metadata">
                                            {rating.releaseDate && (
                                                <span className="user-ratings-year">
                                                    {new Date(rating.releaseDate).getFullYear()}
                                                </span>
                                            )}
                                            {rating.director && (
                                                <>
                                                    <span className="user-ratings-separator">|</span>
                                                    <span className="user-ratings-director">
                                                        {rating.director}
                                                    </span>
                                                </>
                                            )}
                                            {rating.runtime && (
                                                <>
                                                    <span className="user-ratings-separator">|</span>
                                                    <span className="user-ratings-runtime">
                                                        {formatRuntime(rating.runtime)}
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                        <div className="user-ratings-overview">
                                            {rating.overview ? rating.overview : "No movie description exists"}
                                        </div>
                                        {rating.overallRating && (
                                            <div className="user-ratings-score">
                                                <AiFillStar className="user-ratings-score-icon" />
                                                <span className="user-ratings-score-value">
                                                    {rating.overallRating}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                    <div className="user-ratings-pagination">
                        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                            <button
                                key={page}
                                onClick={() => handlePageChange(page)}
                                className={`user-ratings-pagination-button ${currentPage === page ? 'active' : ''}`}
                            >
                                {page}
                            </button>
                        ))}
                    </div>
                </>
            ) : (
                <p className="user-ratings-no-ratings">
                    You haven't rated any movies yet. Start rating horror movies to build your Horror Profile!
                </p>
            )}
        </div>
    );
}
