import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import MovieSearchBar from './moviesearchbar/MovieSearchBar';
import { searchMovies } from '../api';
import './SearchResults.css';

const SearchResults = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const searchTerm = searchParams.get('q') || '';

  const handleSearch = async (term) => {
    setLoading(true);
    setError(null);

    try {
      const response = await searchMovies(term);
      setResults(response.data);
    } catch (err) {
      setError('An error occurred while searching. Please try again.');
      console.error('Search error:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchTerm) {
      handleSearch(searchTerm);
    }
  }, [searchTerm]);

  const onSearch = (term) => {
    navigate(`/search?q=${encodeURIComponent(term)}`);
  };

  return (
    <div className="search-results">
      <div className="search-results-mobile"><MovieSearchBar onSearch={onSearch} /></div>
      
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>{error}</div>
      ) : results.length > 0 ? (
        <div className="search-results-container">
          {results.map((movie) => (
            <Link to={`/movie/${movie.id}`} key={movie.id} className="search-results-movie-link">
              <div className="search-results-movie">
                <div className="search-results-movie-poster">
                  <img src={`https://image.tmdb.org/t/p/w200${movie.posterPath}`} alt={`${movie.title} poster`} />
                </div>
                <div className="search-results-movie-info">
                  <h3 className="search-results-movie-title">{movie.title}</h3>
                  <p className="search-results-movie-year">{new Date(movie.releaseDate).getFullYear()}</p>
                  <p className="search-results-movie-overview">{movie.overview}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      ) : searchTerm ? (
        <div>No results found for "{searchTerm}"</div>
      ) : null}
    </div>
  );
};

export default SearchResults;