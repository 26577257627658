import React, { useState, useEffect } from 'react'
import './ReviewPage.css'
import { useParams, useNavigate } from 'react-router-dom';
import contentfulClient from '../../helpers/ContentfulApi';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import moment from 'moment';

export default function ReviewPage() {
    const navigate = useNavigate()
    const { id } = useParams();
    const [loading, setLoading] = useState(() => { return true })
    const [review, setReview] = useState(() => { return false })
    const fetchReview = () => {
        if (!id) return
        try {
            contentfulClient.getEntries({
                content_type: "review",
                'fields.horrrorVerseId': id,
                limit: 1
            }).then((response) => {
                if (response.items.length === 0) return navigate(`/movie/${id}`)
                setReview(response.items[0].fields)
                setLoading(false)
            })
            
        } catch (error) {
            
        }
    }
    const options = {
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                const { file, title, description } = node.data.target.fields;
                const imageUrl = file.url;
                return `<a href="${imageUrl}" target="_blank" rel="noopener noreferrer"><img src="${imageUrl}" alt="${description || title}" /></a>`
            },
            [INLINES.HYPERLINK]: (node, next) => `<a href="${node.data.uri}" target="_blank" rel="noopener noreferrer">${next(node.content)}</a>`
        }
    };
    const goToMoviePage = () => {
        try {
            navigate(`/movie/${id}`)
        } catch (error) {
            return
        }
    }
    useEffect(() => {
        fetchReview()
    // eslint-disable-next-line
    }, [id])
    if (loading) return <div>Loading...</div>
    const getRatingText = (score) => {
        if (!score) return '';
        const numScore = Number(score);
        
        switch (true) {
            case numScore >= 9.5:
                return 'Masterpiece';
            case numScore >= 9:
                return 'Outstanding';
            case numScore >= 8:
                return 'Very Good';
            case numScore >= 7:
                return 'Good';
            case numScore >= 6:
                return 'Acceptable';
            case numScore >= 5:
                return 'Average';
            case numScore >= 4:
                return 'Below Average';
            case numScore >= 3:
                return 'Poor';
            case numScore >= 2:
                return 'Very Poor';
            default:
                return 'Terrible';
        }
    };
    return (
        <div className="review-page">
            <span className="review-page-header-decoration" onClick={goToMoviePage}>{review.movieName}</span>
            <div className="review-page-header">
                <h1 className="review-page-title">{review.movieName} Review</h1>
                <div className="review-page-subtitle">{review.reviewSubtitle}</div>
                <div className="review-page-meta">
                    <span className="review-page-author">
                        {review.writerReference.fields.firstName} {review.writerReference.fields.lastName}
                    </span>
                    <span className="review-page-date">{moment(review.published).format('MMMM D, YYYY')}</span>
                    {review.containsSpoilers && (
                        <span className="review-page-spoiler-warning">Spoilers</span>
                    )}
                </div>
            </div>
            <div className="review-page-banner">
                <img src={review.bannerImage.fields.file.url} alt={review.bannerImage.fields.file.title} />
            </div>
            <div 
                className="review-page-content"
                dangerouslySetInnerHTML={{__html: documentToHtmlString(review.content, options)}}
            ></div>
            <hr />
            <div className="review-page-ratings">
                <div className="review-page-rating-container">
                    <div className="review-page-rating-main">{review.overallScore}</div>
                    <div className="review-page-rating-text">{getRatingText(review.overallScore)}</div>
                </div>
            </div>
        </div>
    )
}
