import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getHorrorMoviesByPlatform } from '../../api';
import { IoPeople, IoPerson, IoChevronBack, IoChevronForward } from "react-icons/io5";
import '../keywords/FeaturedKeyword.css'
import '../userlists/UserLists.css'

const PROVIDER_SQUARE_LOGO_URLS = {
  netflix: "https://storage.googleapis.com/horrrorverse-public/providers/netflix-logo-app.png",
  shudder: "https://storage.googleapis.com/horrrorverse-public/providers/shudder-logo-app.png",
  prime: "https://storage.googleapis.com/horrrorverse-public/providers/prime-video-app-icon.png",
  hulu: "https://storage.googleapis.com/horrrorverse-public/providers/hulu-logo-app.jpg",
  peacock: "https://storage.googleapis.com/horrrorverse-public/providers/peacock-logo-app.png",
  max: "https://storage.googleapis.com/horrrorverse-public/providers/max-logo-app.png"
}
export default function MoviesByProvider() {
    const { keyword } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [otherMovies, setOtherMovies] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [cache, setCache] = useState({});
    const navigate = useNavigate();
    const [activeHoverItem, setActiveHoverItem] = useState(null);
    const [hoverMenuPosition, setHoverMenuPosition] = useState({ top: 0, left: '100%' });
    const hoverTimeoutRef = useRef(null);
    const appearTimeoutRef = useRef(null);
    const gridRef = useRef(null);
    const currentPage = parseInt(searchParams.get('page') || '1', 10);
    const fetchMoviesByKeyword = useCallback(async (page) => {
      try {
        if (cache[page]) {
          return cache[page];
        }
  
        const response = await getHorrorMoviesByPlatform(keyword, page);
        const { results, totalPages: totalPagesFromAPI } = response.data;
  
        setCache(prevCache => ({
          ...prevCache,
          [page]: { results, totalPages: totalPagesFromAPI }
        }));
  
        setTotalPages(totalPagesFromAPI);
        return { results, totalPages: totalPagesFromAPI };
      } catch (error) {
        console.error('Error fetching movies by keyword:', error);
        return null;
      } finally {
        setLoading(false);
      }
    }, [keyword, cache]);
    useEffect(() => {
      const loadPage = async () => {
        const data = await fetchMoviesByKeyword(currentPage);
        if (data) {
          setOtherMovies(data.results);
          if (currentPage > data.totalPages) {
            // Redirect to the last available page
            navigate(`/keyword/${keyword}?page=${data.totalPages}`);
          }
        }
      };
  
      loadPage();
    }, [currentPage, fetchMoviesByKeyword, keyword, navigate]);
    const handlePageChange = (newPage) => {
      setSearchParams({ page: newPage.toString() });
    };  
    const handleMouseEnter = useCallback((movieId, event) => {
      if (hoverTimeoutRef.current) {
        clearTimeout(hoverTimeoutRef.current);
      }
      
      const gridRect = gridRef.current.getBoundingClientRect();
      const itemRect = event.currentTarget.getBoundingClientRect();
      
      appearTimeoutRef.current = setTimeout(() => {
        const hoverMenuWidth = 375;
        const hoverMenuHeight = 300;
  
        let left = '100%';
        let top = 0;
        let right = 'auto';
        let bottom = 'auto';
  
        if (itemRect.right + hoverMenuWidth > gridRect.right) {
          left = 'auto';
          right = '100%';
        }
  
        if (itemRect.bottom + hoverMenuHeight > gridRect.bottom) {
          top = 'auto';
          bottom = 0;
        }
  
        setHoverMenuPosition({ top, left, right, bottom });
        setActiveHoverItem(movieId);
      }, 200);
    }, []);
    const handleMouseLeave = useCallback(() => {
      if (appearTimeoutRef.current) {
        clearTimeout(appearTimeoutRef.current);
      }
      hoverTimeoutRef.current = setTimeout(() => {
        setActiveHoverItem(null);
      }, 500);
    }, []);
    const renderPagination = () => {
      if (totalPages <= 1) return null;
  
      const pageNumbers = [];
      const maxVisiblePages = 5;
      let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
      let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);
  
      if (endPage - startPage + 1 < maxVisiblePages) {
        startPage = Math.max(1, endPage - maxVisiblePages + 1);
      }
  
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
  
      return (
        <div className="featured-keyword-pagination">
          <button
            onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
            disabled={currentPage === 1}
            className="pagination-nav-button"
          >
            <IoChevronBack />
          </button>
          {startPage > 1 && (
            <>
              <button onClick={() => handlePageChange(1)}>1</button>
              {startPage > 2 && <span className="pagination-ellipsis">...</span>}
            </>
          )}
          {pageNumbers.map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              className={currentPage === page ? 'active' : ''}
            >
              {page}
            </button>
          ))}
          {endPage < totalPages && (
            <>
              {endPage < totalPages - 1 && <span className="pagination-ellipsis">...</span>}
              <button onClick={() => handlePageChange(totalPages)}>{totalPages}</button>
            </>
          )}
          <button
            onClick={() => handlePageChange(Math.min(totalPages, currentPage + 1))}
            disabled={currentPage === totalPages}
            className="pagination-nav-button"
          >
            <IoChevronForward />
          </button>
        </div>
      );
    };
    if (loading && currentPage === 1 && !cache[1]) {
      return (
        <div className="featured-keyword-loading">
          <div className="loading-spinner"></div>
        </div>
      );
    }
    const getProviderLogoUrl = () => {
      try {
        if (!keyword) return
        if (PROVIDER_SQUARE_LOGO_URLS[keyword.toLowerCase()]) return PROVIDER_SQUARE_LOGO_URLS[keyword.toLowerCase()]
        return false
      } catch (error) {
        return false
      }
    }
    return (
      <div className="featured-keyword-container">
        <h1 className="featured-keyword-title featured-keyword-title-with-logo">
          {getProviderLogoUrl() && <div className="featured-keyword-title-logo"> <img src={getProviderLogoUrl()} alt={keyword} /></div>}
          {keyword.replace(/-/g, ' ')}
        </h1>
                
        <section className="featured-keyword-other-movies">
          <div className="featured-keyword-grid user-list-desktop" ref={gridRef}>
            {otherMovies.map((movie) => (
              <div
                key={movie.id}
                className="featured-keyword-poster-item"
                onMouseEnter={(event) => handleMouseEnter(movie.id, event)}
                onMouseLeave={handleMouseLeave}
              >
                <div 
                  onClick={() => navigate(`/movie/${movie.id}`)}
                  style={{ cursor: 'pointer' }}
                >
                  <div className="featured-keyword-poster-image-container">
                    <img
                      src={`https://image.tmdb.org/t/p/w200${movie.posterPath}`}
                      alt={movie.title}
                    />
                  </div>
                  <div className="featured-keyword-poster-subtitle">
                    <span>{new Date(movie.releaseDate).getFullYear()}</span>
                    {movie.averageRating && (
                      <span style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                        <IoPerson /> {movie.averageRating.toFixed(1)}
                      </span>
                    )}
                  </div>
                </div>
                {activeHoverItem === movie.id && (
                  <div className="user-list-item-hover-menu" style={hoverMenuPosition}>
                    <div className="user-list-item-hover-title-container">
                      <h3 
                        className="user-list-item-hover-title" 
                        onClick={() => navigate(`/movie/${movie.id}`)}
                      >
                        {movie.title}
                      </h3>
                    </div>
                    <div className="user-list-item-hover-meta">
                      <p className="user-list-item-hover-release-date">
                        {new Date(movie.releaseDate).getFullYear()}
                      </p>
                      <div className="user-list-item-hover-ratings">
                        {movie.averageRating != null ? (
                          <p className="user-list-item-hover-rating">
                            <IoPerson /> {movie.averageRating.toFixed(1)}
                          </p>
                        ) : movie.voteAverage != null ? (
                          <p className="user-list-item-hover-rating">
                            <IoPeople /> {movie.voteAverage.toFixed(1)}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="user-list-item-hover-summary-container">
                      <p className="user-list-item-hover-summary">{movie.overview}</p>
                    </div>
                    <div className="user-list-item-hover-buttons">
                      <button 
                        onClick={() => navigate(`/movie/${movie.id}`)} 
                        className="user-list-item-see-more-btn"
                      >
                        See more
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ))}
            {otherMovies.length === 0 && <div>
                <div>Nothing found here</div>
                <p>Come back later</p>
            </div> }
          </div>
          {loading && <div className="loading-spinner"></div>}
          {renderPagination()}
        </section>
      </div>
    );
}
