import React from 'react';
import TenStarRating from './gizmos/TenStarRating';
import './ratingpage/RatingPage.css';

const ratingCategories = [
  { name: 'scares', display_name: 'Scares', description: 'The level of fear, tension, and adrenaline-inducing moments throughout the film.' },
  { name: 'atmosphere', display_name: 'Atmosphere', description: 'Mood, ambiance, and sense of dread or unease created by the setting, cinematography, and sound design.' },
  { name: 'plot', display_name: 'Plot', description: 'Quality and coherence of the storyline, including pacing and plot development.' },
  { name: 'lore', display_name: 'Lore', description: 'Richness and intrigue of the backstory, world-building, or supernatural elements underlying the main plot.' },
  { name: 'villain', display_name: 'Villain', description: 'Memorability, frightfulness, and overall effectiveness of the antagonist or monster, whether physical or psychological.' },
  { name: 'gore', display_name: 'Gore', description: 'The level and quality of graphic violence, blood, and visceral horror elements.' },
  { name: 'acting', display_name: 'Acting', description: 'The quality of performances by the cast, including their ability to convey fear and emotion.' },
  { name: 'unpredictability', display_name: 'Unpredictability', description: 'The film\'s ability to surprise the audience and subvert expectations.' }
];

export default function DetailedRatings({ detailedRatings, onRatingChange, isEditing }) {
  if (!detailedRatings) {
    return null;
  }

  if (isEditing) {
    return (
      <div className="rating-page-detailed-ratings rating-page-detailed-ratings-container">
        {ratingCategories.map((category) => (
          <div key={category.name} className="rating-page-category">
            <h4 className="rating-page-container-heading">{category.display_name}</h4>
            <p>{category.description}</p>
            <TenStarRating handleRatingChange={onRatingChange} categoryName={category.name} currentRating={detailedRatings[category.name]} />
          </div>
        ))}
      </div>
    );
  }
  return null;
}