import React, { useState, useEffect } from 'react';
import DetailedRatings from '../DetailedRatings';
import './RatingPage.css';
import TenStarRating from '../gizmos/TenStarRating';
import { submitRating } from '../../api';
import { IoClose } from "react-icons/io5";

export default function RatingPage({ movieId, onClose, onRatingUpdate, initialOverallRating, initialDetailedRatings }) {
  const [overallRating, setOverallRating] = useState(initialOverallRating);
  const [detailedRatings, setDetailedRatings] = useState(initialDetailedRatings || {});

  // Prevent body scroll when modal is open
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const handleOverallRatingChange = (categoryName, newRating) => {
    setOverallRating(newRating);
  };

  const handleDetailedRatingChange = (category, newRating) => {
    setDetailedRatings(prev => ({ ...prev, [category]: newRating }));
  };

  const hasRatingsChanged = () => {
    if (overallRating !== initialOverallRating) return true;

    const detailedCategories = new Set([
      ...Object.keys(initialDetailedRatings || {}),
      ...Object.keys(detailedRatings)
    ]);

    for (const category of detailedCategories) {
      const initialValue = (initialDetailedRatings || {})[category] ?? null;
      const currentValue = detailedRatings[category] ?? null;
      if (initialValue !== currentValue) return true;
    }

    return false;
  };

  const handleSave = async () => {
    if (!hasRatingsChanged()) {
      onClose();
      return;
    }

    try {
      const validDetailedRatings = Object.entries(detailedRatings).reduce((acc, [key, value]) => {
        if (value !== null && value !== undefined && value >= 0 && value <= 10) {
          acc[key] = value;
        }
        return acc;
      }, {});

      await submitRating(movieId, { overallRating, detailedRatings: validDetailedRatings });
      onRatingUpdate({ overallRating, detailedRatings: validDetailedRatings });
      onClose();
    } catch (err) {
      console.error('Error updating rating:', err);
    }
  };

  return (
    <div className="rating-page-modal-overlay">
      <div className="rating-page-close" onClick={onClose}>
        <IoClose />
      </div>
      <div className="rating-page-container">
        <div className="rating-page-category">
          <h4 className="rating-page-container-heading">Overall Rating</h4>
          <p>Your holistic assessment of the film, taking into account all aspects and your personal enjoyment.</p>
          <TenStarRating 
            categoryName="overall" 
            currentRating={overallRating} 
            handleRatingChange={handleOverallRatingChange} 
          />
        </div>

        <DetailedRatings 
          detailedRatings={detailedRatings} 
          onRatingChange={handleDetailedRatingChange} 
          isEditing={true} 
          hideSpiderChart={true}
        />

        <div className="rating-page-rating-actions">
          <div onClick={handleSave} className="rating-page-save-rating">Submit ratings</div>
          <div onClick={onClose} className="rating-page-cancel-rating">Cancel</div>
        </div>
      </div>
    </div>
  );
}