import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import RatingPage from './ratingpage/RatingPage';
import DetailedRatingsSpiderchart from './DetailedRatingsSpiderchart';
import contentfulClient from '../helpers/ContentfulApi';
import CastSection from './CastSection';
import MovieDetailKeywords from './movies/MovieDetailKeywords';
import MoviePageTrailer from './movies/MoviePageTrailer';
import './MovieDetails.css';
import './MoviePage.css';
import './InteractionButtons.css';
import { useWatchlist } from '../context/WatchlistContext';
import { IoPerson, IoPeople, IoTicket, IoCheckmark, IoAddSharp, IoPlay } from "react-icons/io5";
import { BiSolidUpvote } from "react-icons/bi";
import { TbListDetails } from "react-icons/tb";
import { getMovieDetails, getUserRating, getUserList, addToUserList, removeFromUserList, updateWatchedStatus } from '../api';

function MovieDetails() {
  const [movie, setMovie] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userRating, setUserRating] = useState(null);
  const [detailedRatings, setDetailedRatings] = useState(null);
  const [showRatings, setShowRatings] = useState(false);
  const ratingsAreaRef = useRef(null)
  const { id } = useParams();
  const { user } = useAuth();
  const [isInList, setIsInList] = useState(false);
  const navigate = useNavigate();
  const { animateWatchlistIcon } = useWatchlist();
  const [averageRating, setAverageRating] = useState(null);
  const [averageDetailedRatings, setAverageDetailedRatings] = useState(null);
  const [watched, setWatched] = useState(false);
  const [keywords, setKeywords] = useState([]);
  const [trailer, setTrailer] = useState(false);
  const [showTrailer, setShowTrailer] = useState(false);
  const [review, setReview] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchMovieDetails();
    fetchReview();
    if (user) {
      fetchUserRating();
      checkIfInList();
    }
  // eslint-disable-next-line
  }, [id, user]);

  const fetchMovieDetails = async () => {
    try {
      const response = await getMovieDetails(id);
      setMovie(response.data);
      // Update this line to round the rating to 1 decimal place
      setAverageRating(
        response.data.averageRating || response.data.vote_average
          ? Number((response.data.averageRating || response.data.vote_average).toFixed(1))
          : null
      );
      setAverageDetailedRatings(response.data.averageDetailedRatings);
      setWatched(response.data.watched);
      setKeywords(response.data.keywords || []);
      setTrailer(response.data.trailer || false);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching movie details:', err);
      setError(err.response?.data?.message || 'An error occurred while fetching movie details');
      setLoading(false);
    }
  };
  const fetchReview = () => {
    if (!id) return
    try {
        contentfulClient.getEntries({
            content_type: "review",
            'fields.horrrorVerseId': id,
            limit: 1
        }).then((response) => {
            if (response.items.length === 0) return setReview(false)
            setReview(response.items[0].fields)
        })
        
    } catch (error) {
        
    }
}
  const fetchUserRating = async () => {
    try {
      const response = await getUserRating(id);
      setUserRating(response.data.overallRating);
      setDetailedRatings(response.data.detailedRatings);
    } catch (err) {
      console.error('Error fetching user rating:', err);
    }
  };

  const checkIfInList = async () => {
    try {
      const response = await getUserList();
      setIsInList(response.data.items.some(item => item.title_id === parseInt(id)));
    } catch (err) {
      console.error('Error checking if movie is in list:', err);
    }
  };

  const handleListActionClick = () => {
    if (!user) {
      navigate(`/login?redirect=/movie/${id}`);
      return;
    }
    handleListAction();
  }

  const handleListAction = async () => {
    if (!user) {
      return;
    }

    try {
      if (isInList) {
        await removeFromUserList(id);
      } else {
        await addToUserList(parseInt(id));
        animateWatchlistIcon();
      }
      setIsInList(!isInList);
    } catch (err) {
      console.error('Error updating list:', err);
    }
  };

  const formatReleaseDate = (dateString) => {
    if (!dateString) return 'No release date';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return 'No release date';
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  const formatRuntime = (minutes) => {
    if (!minutes) return null;
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  };

  const handleRateClick = () => {
    if (!isMovieReleased()) return
    setShowRatings(true);
    if (ratingsAreaRef.current) {
      ratingsAreaRef.current.scrollIntoView({ 
        behavior: 'smooth', 
        block: 'start' 
      });
    }
  };

  const handleCloseRatings = () => {
    setShowRatings(false);
    fetchMovieDetails()
  };

  const handleRatingUpdate = (newRatings) => {
    setUserRating(newRatings.overallRating);
    setDetailedRatings(newRatings.detailedRatings);
  };

  const isMovieReleased = () => {
    return new Date(movie.release_date) <= new Date();
  };

  const handleUserRateClick = () => {
    if (!user) {
      navigate(`/login?redirect=/movie/${id}`);
      return;
    }
    handleRateClick();
  }
  const renderStreamingSection = () => {
    if (!movie.watch_providers || !isMovieReleased()) return null;

    const streamProviders = movie.watch_providers.stream || [];

    return (
      <div className="streaming-section">
        <h2 className="movie-details-section-title">Streaming On</h2>
        {streamProviders.length > 0 ? (
          <div className="streaming-list">
            {streamProviders.map(provider => (
              <div 
                key={provider.provider_id} 
                className="streaming-provider"
                // onClick={() => navigate(`/provider/${provider.provider_name.toLowerCase()}`)}
              >
                <img src={`https://image.tmdb.org/t/p/original${provider.logo_path}`} alt={provider.provider_name} />
                <span>{provider.provider_name}</span>
              </div>
            ))}
          </div>
        ) : (
          <p>Not available for streaming</p>
        )}
      </div>
    );
  };

  const handleWatchedToggle = async () => {
    if (!user) {
      navigate(`/login?redirect=/movie/${id}`);
      return;
    }

    const newWatchedStatus = !watched;
    setWatched(newWatchedStatus);
    await updateWatchedStatus(id, newWatchedStatus);
  };

  const renderWatchedButton = () => {
    return (
      <div className="action-row-item">
      <div 
        className={`action-icon ${watched ? 'watched' : ''}`} 
        onClick={handleWatchedToggle}
        title={watched ? 'Mark as unwatched' : 'Mark as watched'}
      >
        {watched ? <IoCheckmark /> : <IoTicket />}
      </div>
      <p>{watched ? "Watched" : "Watch"}</p>
      </div>
    );
  };

  const handleTrailerClick = () => {
    setShowTrailer(true);
  };

  const handleCloseTrailer = () => {
    setShowTrailer(false);
  };
  const goToReview = () => {
    try {
      if (!review) return
      navigate(`/movie/${id}/review`)
    } catch (error) {
      return
    }
  }

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-container">
        <div className="error-content">
          <h2>Oops! Something went wrong.</h2>
          <p>{error}</p>
          <button onClick={() => navigate(-1)} className="go-back-button">Go Back</button>
        </div>
      </div>
    );
  }

  const showSpiderChart = averageDetailedRatings && Object.keys(averageDetailedRatings).length >= 3;

  return (
    <div className="movie-details">
      <div className="movie-content">
        {userRating !== null && user && <div className="user-rating-badge">{userRating}</div>}
        {averageRating !== null && <div className="movie-details-average-rating-badge">Community Rating: {averageRating.toFixed(1)}/10</div>}
        <div className="movie-info-title-and-meta movie-info-title-and-meta-mobile">
          <h1>{movie.title}</h1>
          <div className="movie-info-meta">
            <span>{formatReleaseDate(movie.release_date)}</span>
            <span>|</span>
            <span>{movie.director}</span>
            {formatRuntime(movie.runtime) && (
              <>
                <span>|</span>
                <span>{formatRuntime(movie.runtime)}</span>
              </>
            )}
          </div>
        </div>
        <div className="movie-poster">
          <div className="poster-placeholder">
            {movie.poster_path ? (
              <img 
                src={`https://image.tmdb.org/t/p/w500${movie.poster_path}`} 
                alt={movie.title} 
                onLoad={(e) => {
                  e.target.classList.add('loaded');
                  if (e.target.offsetHeight < e.target.parentElement.offsetHeight) {
                    e.target.style.height = 'auto';
                  }
                }}
              />
            ) : (
              <div className="no-image">{movie.title}</div>
            )}
          </div>

          <div className="rating-section rating-section-desktop">
          {trailer && (
            <div className="trailer-section-button" onClick={handleTrailerClick}>
              <IoPlay />
              Watch Trailer
            </div>
          )}
            {user && (
              <div className="action-row">
                <div className="action-row-item">
                  <div className={`action-icon ${isInList ? 'in-list' : ''}`} onClick={handleListActionClick} title={isInList ? 'Remove from my list' : 'Add to my list'}>
                    {isInList ? <TbListDetails /> : <IoAddSharp />}
                  </div>
                  <p>Watchlist</p>
                </div>

                {user && isMovieReleased() && renderWatchedButton()}

                {isMovieReleased() && 
                <div className="action-row-item">
                  <div className="action-icon" style={userRating !== null ? {backgroundColor: "unset"} : {}} onClick={handleUserRateClick}>
                    {userRating !== null && <span className="rating-value" style={{color: "rgb(219, 219, 219)"}}>{userRating}<span>/10</span></span>}
                    {userRating === null && <BiSolidUpvote />}
                  </div>
                  <p>{userRating !== null ? "Rating" : "Rate"}</p>
                </div>
                }
              </div>
            )}
            {review ?
              <div className="movie-details-review-section" onClick={goToReview}>
                <div className="movie-details-review-score">{review.overallScore}</div>
                <div className="movie-details-review-title">
                  <span>{review.reviewSubtitle}</span>
                  <span>Read review</span>
                </div>
              </div>
              : <></>
            }
            
            {renderStreamingSection()}
          </div>

          <div className="movie-detail-keywords-desktop">
            <MovieDetailKeywords keywords={keywords} />
          </div>

        </div>
        <div className="movie-info">
          <div className="movie-info-title-container">
            <div className="movie-info-title-row">
              <div className="movie-info-title-and-meta">
                <h1>{movie.title}</h1>
                <div className="movie-info-meta">
                  <span>{formatReleaseDate(movie.release_date)}</span>
                  <span>|</span>
                  <span>{movie.director}</span>
                  {formatRuntime(movie.runtime) && (
                    <>
                      <span>|</span>
                      <span>{formatRuntime(movie.runtime)}</span>
                    </>
                  )}
                </div>
              </div>
              {trailer && (
                  <div className="trailer-section-button trailer-section-button-mobile" onClick={handleTrailerClick}>
                    <IoPlay />
                    Watch Trailer
                  </div>
                )}
              <div className="movie-info-ratings">
                <div className="action-row action-row-mobile">
                  {/* USER RATING - AUTH */}
                  {isMovieReleased() && user &&
                  <div className="action-row-item">
                    <div className="action-icon" style={userRating !== null ? {backgroundColor: "unset"} : {}} onClick={handleUserRateClick}>
                      {userRating !== null && <span className="rating-value" style={{color: "var(--text-color)"}}>{userRating}<span>/10</span></span>}
                      {userRating === null && <BiSolidUpvote />}
                    </div>
                    <p>{userRating !== null ? "Rating" : "Rate"}</p>
                  </div>
                  }

                  {isMovieReleased() && !user &&
                  <div className="action-row-item">
                    <div className="action-icon" onClick={handleUserRateClick}>
                      <BiSolidUpvote />
                    </div>
                    <p>Rate</p>
                  </div>
                  }

                  {/* COMMUNITY RATING */}
                  {averageRating !== null &&
                  <div className="action-row-item">
                    <div className="action-icon" style={{backgroundColor: "unset"}}>
                      <span className="rating-value" style={{color: "rgb(155, 192, 177)"}}>{averageRating.toFixed(1)}<span>/10</span></span>
                    </div>
                    <p>Community</p>
                  </div>
                  }

                  {/* WATCHLIST */}
                  {user &&
                  <div className="action-row-item">
                    <div className={`action-icon ${isInList ? 'in-list' : ''}`} onClick={handleListActionClick} title={isInList ? 'Remove from my list' : 'Add to my list'}>
                      {isInList ? <TbListDetails /> : <IoAddSharp />}
                    </div>
                    <p>Watchlist</p>
                  </div>
                  }

                  {/* WATCH */}
                  {user && isMovieReleased() && renderWatchedButton()}
                </div>

                {user && isMovieReleased() && (
                  <div className="rating-box user-rating user-rating-desktop" onClick={handleUserRateClick} style={{cursor: "pointer"}}>
                    <span className="rating-label" style={{color: "var(--primary-color)"}}><IoPerson/></span>
                    {user && userRating !== null ? (
                      <span className="rating-value" style={{color: "var(--text-color)"}}>{userRating}<span>/10</span></span>
                    ) : (
                      <span className="rating-value" style={{color: "var(--text-color)", fontWeight: 500, fontSize: "14px"}}>Rate Title</span>
                    )}
                  </div>
                )}
                {!user && isMovieReleased() &&
                <div className="rating-box user-rating user-rating-desktop" onClick={handleUserRateClick} style={{cursor: "pointer"}}>
                  <span className="rating-label" style={{color: "var(--text-color)"}}><IoPerson/></span>
                  <span className="rating-value" style={{color: "var(--text-color)", fontWeight: 500, fontSize: "14px"}}>Rate Title</span>
                </div>
                }
                {averageRating !== null && (
                  <div className="rating-box community-rating user-rating-desktop">
                    <span className="rating-label" style={{color: "rgb(155, 192, 177)"}}><IoPeople/></span>
                    <span className="rating-value" style={{color: "rgb(155, 192, 177)"}}>{averageRating.toFixed(1)}<span>/10</span></span>
                  </div>
                )}
              </div>
              
            </div>
          </div>
          
          <div className="info-section">
            <p>{movie.overview}</p>
          </div>
          <div className="movie-detail-keywords-mobile">
            <MovieDetailKeywords keywords={keywords} />
          </div>
          {review ?
          <div className="movie-review-section-mobile">
              <div className="movie-details-review-section" onClick={goToReview}>
                <div className="movie-details-review-score">{review.overallScore}</div>
                <div className="movie-details-review-title">
                  <span>{review.reviewSubtitle}</span>
                  <span>Read review</span>
                </div>
              </div>
          </div>
          : <></>
          }
          <div className="info-and-chart-container">
            {showSpiderChart && (
              <div className="spider-chart-container">
                <h2 className="movie-details-section-title">Fright Graph</h2>
                <DetailedRatingsSpiderchart 
                  userRatings={detailedRatings} 
                  communityRatings={averageDetailedRatings} 
                />
              </div>
            )}
          </div>
          {movie.cast && movie.cast.length > 0 && (
            <>
              <div className="cast-section-desktop">
                <CastSection cast={movie.cast} />
              </div>
              <div className="cast-section-mobile">
                <div className="cast-section">
                  <h2 className="movie-details-section-title">Cast</h2>
                  <div className="cast-carousel">
                    <div className="cast-list">
                      {movie.cast.map(member => (
                        <div key={member.id} className="cast-member" onClick={() => navigate(`/person/${member.id}`)}>
                          <div className="cast-portrait">
                            {member.profile_path ? (
                              <img 
                                src={`https://image.tmdb.org/t/p/w185${member.profile_path}`} 
                                alt={member.name}
                                loading="lazy"
                              />
                            ) : (
                              <div className="placeholder-portrait">
                                {member.name.charAt(0)}
                              </div>
                            )}
                          </div>
                          <div className="cast-name">{member.name}</div>
                          <div className="cast-character">{member.character}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {renderStreamingSection()}
              </div>
            </>
          )}

          {user && !showRatings &&
          <div className="rating-section rating-section-mobile">
            {!showRatings && userRating !== null && isMovieReleased() && <div className="rating-section-button" onClick={handleRateClick}>Update rating</div>}
            {!showRatings && userRating === null && isMovieReleased() && <div className="rating-section-button" onClick={handleRateClick}>Rate title</div>}
          </div>
          }
        </div>
      </div>

      <div ref={ratingsAreaRef} style={{paddingTop: "40px"}}>
        {showRatings && user && (
          <RatingPage 
            movieId={id} 
            onClose={handleCloseRatings} 
            onRatingUpdate={handleRatingUpdate}
            initialOverallRating={userRating}
            initialDetailedRatings={detailedRatings}
          />
        )}
      </div>

      <MoviePageTrailer 
        trailer={trailer} 
        showTrailer={showTrailer} 
        handleCloseTrailer={handleCloseTrailer} 
      />
    </div>
  );
}

export default MovieDetails;