import React, { useEffect, useState } from 'react';
import './Home.css'
import moment from 'moment';
import { getTrendingHorrorMovies, getRecentlyReleasedHorrorMovies } from '../../api';
import { useNavigate } from 'react-router-dom';
import { IoStar } from "react-icons/io5";
import HomeFeaturedReviewPreview from './HomeFeaturedReviewPreview';

export default function Home() {
    const navigate = useNavigate()
    const [recentReleases, setRecentReleases] = useState(() => { return [] })
    const [trending, setTrending] = useState(() => { return [] })
    // const [featuredReviewData, setFeaturedReviewData] = useState(() => { return false })
    const sidebar = [
        {
            _id: "theme",
            title: "Browse Theme", 
            browse_type: "keyword",
            items: [
                {
                    name: 'Supernatural',
                    value: 'supernatural',
                    backgroundColor: '#2C3E50',
                    fontColor: '#E8F6F3'
                },
                {
                    name: 'Haunted House',
                    value: 'haunted-house',
                    backgroundColor: '#34495E',
                    fontColor: '#FADBD8'
                },
                {
                    name: 'Possession',
                    value: 'possession',
                    backgroundColor: '#8E44AD',
                    fontColor: '#F9E79F'
                },
                {
                    name: 'Witchcraft',
                    value: 'witchcraft',
                    backgroundColor: '#6C3483',
                    fontColor: '#ABEBC6'
                },
                {
                    name: 'Demon',
                    value: 'demon',
                    backgroundColor: '#943126',
                    fontColor: '#F9E79F'
                },
                {
                    name: 'Exorcism',
                    value: 'exorcism',
                    backgroundColor: '#7B241C',
                    fontColor: '#D5F5E3'
                },
                {
                    name: 'Slasher',
                    value: 'slasher',
                    backgroundColor: '#C0392B',
                    fontColor: '#FAE5D3'
                },
                {
                    name: 'Werewolf',
                    value: 'werewolf',
                    backgroundColor: '#935116',
                    fontColor: '#D5F5E3'
                },
                {
                    name: 'Vampire',
                    value: 'vampire',
                    backgroundColor: '#78281F',
                    fontColor: '#F4ECF7'
                },
                {
                    name: 'Zombie',
                    value: 'zombie',
                    backgroundColor: '#196F3D',
                    fontColor: '#FAE5D3'
                },
                {
                    name: 'Ghost',
                    value: 'ghost',
                    backgroundColor: '#1B4F72',
                    fontColor: '#F2F4F4'
                },
                {
                    name: 'Cult',
                    value: 'cult',
                    backgroundColor: '#7D6608',
                    fontColor: '#D5F5E3'
                },
                {
                    name: 'Psychological Thriller',
                    value: 'psychological-thriller',
                    backgroundColor: '#145A32',
                    fontColor: '#FAE5D3'
                },
                {
                    name: 'Based on a Book',
                    value: 'based-on-novel-or-book',
                    backgroundColor: '#186A3B',
                    fontColor: '#F2F4F4'
                },
                {
                    name: 'Found Footage',
                    value: 'found-footage',
                    backgroundColor: '#186A3B',
                    fontColor: '#F2F4F4'
                },
                {
                    name: 'Monster',
                    value: 'monster',
                    backgroundColor: '#6E2C00',
                    fontColor: '#D5F5E3'
                },
                {
                    name: 'Body Horror',
                    value: 'body-horror',
                    backgroundColor: '#922B21',
                    fontColor: '#F4ECF7'
                },
                {
                    name: 'Urban Legend',
                    value: 'urban-legend',
                    backgroundColor: '#4A235A',
                    fontColor: '#ABEBC6'
                }
            ]
        },
        {
            _id: "platform",
            title: "Browse Platform",
            browse_type: "platform", 
            items: [
                { name: "Netflix", value: "netflix", backgroundColor: "#e50914", fontColor: "#ffffff" },
                { name: "Shudder", value: "shudder", backgroundColor: "#AF0000", fontColor: "#ffffff" },
                { name: "Prime Video", value: "prime", backgroundColor: "#00a8e1", fontColor: "#000000" },
                { name: "Hulu", value: "hulu", backgroundColor: "#1CE783", fontColor: "#000000" },
                { name: "Peacock", value: "peacock", backgroundColor: "#000e33", fontColor: "#ffffff" },
                { name: "HBO Max", value: "max", backgroundColor: "#0021B2", fontColor: "#ffffff" },
            ]
        },
        // {
        //     _id: "year",
        //     title: "Release Year",
        //     browse_type: "year",
        //     items: [
        //         { name: "2024", value: "2024", backgroundColor: "#2d4059", fontColor: "#ffffff" },
        //         { name: "2023", value: "2023", backgroundColor: "#3d5a80", fontColor: "#ffffff" },
        //         { name: "2022", value: "2022", backgroundColor: "#4d6ba6", fontColor: "#ffffff" },
        //         { name: "2021", value: "2021", backgroundColor: "#5d7cc6", fontColor: "#ffffff" },
        //     ]
        // }
    ]
    const handleSidebarItemClick = (sidebarItem, item) => {
        try {
            if (sidebarItem.browse_type === "keyword") return navigate(`/keyword/${item.value}`)
            if (sidebarItem.browse_type === "platform") return navigate(`/provider/${item.value}`)
        } catch (error) {
            return
        }
    }
    const initTrendingHorror = () => {
        getTrendingHorrorMovies()
        .then((res) => {
            if (res.data) {
                setTrending(res.data)
            }
        })
    }
    const initRecentReleases = () => {
        getRecentlyReleasedHorrorMovies()
        .then((res) => {
            if (res.data) setRecentReleases(res.data)
        })
    }
    useEffect(() => {
        initTrendingHorror()
        initRecentReleases()
    }, [])
    return (
        <div className="home">
            <div className="home-desktop">
                <div className="home-desktop-sidebar">
                    {sidebar.map((browseType) => (
                        <div key={browseType._id} style={{marginBottom: "2rem"}}>
                            <h4>{browseType.title}</h4>
                            <div className="home-desktop-browse-items">
                                {browseType.items.map((item) => (
                                    <span key={item.value} onClick={() => handleSidebarItemClick(browseType, item)}>{item.name}</span>
                                ))}
                                {/* <div className="home-desktop-browse-see-more">See More</div> */}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="home-desktop-main">

                    <div className="home-desktop-section">                    
                        <div className="home-desktop-section-title">
                            <h3>Recently Released</h3>
                            <span onClick={() => navigate('/calendar')}>View release calendar</span>
                        </div>
                        
                        <div className="home-desktop-simple-poster-list">
                            {recentReleases.slice(0,5).map((movie) => (
                                <MovieListDisplayItem movie={movie} key={movie.id} />
                            ))}
                        </div>
                    </div>

                    <div className="home-desktop-section">
                        <h2>Welcome to HorrrorVerse</h2>
                        <p>HorrrorVerse is a place to find new horror films to watch. Build a watchlist, rate movies you've seen, and discover new scares along the way.</p>
                    </div>

                    <HomeFeaturedReviewPreview />


                    <div className="home-desktop-section">
                        <div className="home-desktop-section-title">
                            <h3>Trending on HorrrorVerse</h3>
                        </div>
                        <div className="home-desktop-simple-poster-list">
                            {trending.slice(0,5).map((movie) => (
                                <MovieListDisplayItem movie={movie} key={movie.id} />
                            ))}
                        </div>
                        {trending.length > 5 &&
                        <div className="home-desktop-simple-poster-list" style={{marginTop: "1rem"}}>
                            {trending.slice(5,10).map((movie) => (
                                <MovieListDisplayItem movie={movie} key={movie.id} />
                            ))}
                        </div>
                        }
                        {trending.length > 10 &&
                        <div className="home-desktop-simple-poster-list" style={{marginTop: "1rem"}}>
                            {trending.slice(10,15).map((movie) => (
                                <MovieListDisplayItem movie={movie} key={movie.id} />
                            ))}
                        </div>
                        }
                    </div>
                </div>
            </div>
            <div className="home-mobile">
                <div className="home-mobile-section">
                    <div className="home-mobile-section-title">
                        <h3>Recently Released</h3>
                        <span onClick={() => navigate('/calendar')}>View calendar</span>
                    </div>
                    
                    <div className="home-mobile-simple-poster-list">
                        {recentReleases.slice(0,3).map((movie) => (
                            <MovieListDisplayItem movie={movie} key={movie.id} />
                        ))}
                    </div>
                </div>

                <div className="home-mobile-section">
                    <h2>Welcome to HorrrorVerse</h2>
                    <p className="home-mobile-general-text">HorrrorVerse is a place to find new horror films to watch. Build a watchlist, rate movies you've seen, and discover new scares along the way.</p>
                </div>

                <HomeFeaturedReviewPreview />

                <div className="home-mobile-section">
                    <div className="home-mobile-section-title">
                        <h3>Trending</h3>
                    </div>
                    
                    <div className="home-mobile-simple-poster-list">
                        {trending.slice(0,3).map((movie) => (
                            <MovieListDisplayItem movie={movie} key={movie.id} />
                        ))}
                    </div>
                    {trending.length > 3 &&
                    <div className="home-mobile-simple-poster-list" style={{marginTop: "0.5rem"}}>
                        {trending.slice(3,6).map((movie) => (
                            <MovieListDisplayItem movie={movie} key={movie.id} />
                        ))}
                    </div>
                    }
                    {trending.length > 6 &&
                    <div className="home-mobile-simple-poster-list" style={{marginTop: "0.5rem"}}>
                        {trending.slice(6,9).map((movie) => (
                            <MovieListDisplayItem movie={movie} key={movie.id} />
                        ))}
                    </div>
                    }
                </div>

                <div className="home-mobile-section">
                    <div className="home-mobile-section-title">
                        <h3>Browse</h3>
                    </div>
                    <div className="home-mobile-browse-buttons">
                        {sidebar.map((sidebarSection) => (
                            <div className="home-mobile-browse-section" key={sidebarSection._id}>
                                {sidebarSection.items.map((browseItem) => (
                                    <span onClick={() => handleSidebarItemClick(sidebarSection, browseItem)} style={{backgroundColor: browseItem.backgroundColor, color: browseItem.fontColor }} key={browseItem.name}>{browseItem.name}</span>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>

            </div>
      </div>
    )
}

function MovieListDisplayItem({ movie }) {
    const navigate = useNavigate()
    const getDate = () => {
        try {
            if (moment(movie.releaseDate).format('MMM Do') === 0) return <></>
            if (!moment(movie.releaseDate).format('MMM Do')) return <></>
            return moment(movie.releaseDate).format('MMM Do')
        } catch (error) {
            return <></>
        }
    }
    return (
        <div className="home-desktop-simple-poster-item" onClick={() => navigate(`/movie/${movie.id}`)}>
            <div className="home-desktop-simple-poster-image-container">
                <img src={`https://image.tmdb.org/t/p/w300${movie.posterPath}`} alt={movie.title} className="home-desktop-simple-poster-image" />
            </div>
            {(movie.averageRating && movie.releaseDate) ?
            <div className="home-desktop-simple-poster-item-subtitle">
                {movie.averageRating ? <span style={{display: "flex", alignItems: "center", gap: "4px"}}><IoStar/> {movie.averageRating.toFixed(1)}</span> : <></> }
                {getDate()}
            </div>
            : <></>
            }
        </div>
    )
}