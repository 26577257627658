import React, { useState, useEffect } from 'react'
import './Home.css'
import contentfulClient from '../../helpers/ContentfulApi'
import { FaChevronRight } from "react-icons/fa6";
import { IoPersonCircleSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';

export default function HomeFeaturedReviewPreview() {
    const navigate = useNavigate()
    const [review, setReview] = useState(() => { return false })
    const [authorData, setAuthorData] = useState(() => { return false })
    const getFeaturedReview = () => {
        try {
            contentfulClient.getEntries({
                content_type: "featuredReview",
                limit: 1
            }).then((response) => {
                if (response.items) {
                    if (response.items[0]) {
                        if (response.items[0].fields) setReview(response.items[0].fields)
                    }
                }
            })
            
        } catch (error) {
            return setReview(false)
        }
    }
    const getAuthorInformation = () => {
        try {
            if (!review) return
            if (authorData) return
            let authorId = review.reviewReference.fields.writerReference.sys.id
            contentfulClient.getEntry(authorId).then((response) => {
                if (response.fields) {
                    if (response.fields.firstName) setAuthorData(response.fields)
                }
            })
            
        } catch (error) {
            return setReview(false)
        }
    }
    const goToReview = () => {
        try {
            if (!review) return
            navigate(`/movie/${review.reviewReference.fields.horrrorVerseId}/review`)
        } catch (error) {
            return
        }
    }
    useEffect(() => {
        getFeaturedReview()
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        getAuthorInformation()
    // eslint-disable-next-line
    }, [review])
    if (!review) return <></>
    return (
        <div className="home-desktop-section">
            <div className="home-desktop-section-title">
                <h3>Featured Review</h3>
            </div>
            <div className="home-featured-review-container">
                <div className="home-featured-review-poster" onClick={goToReview}>
                    <img src={review.reviewReference.fields.posterArt.fields.file.url} alt="Featured Review" />
                </div>
                <div className="home-featured-review-information">
                    <h4 onClick={goToReview}>{review.reviewReference.fields.movieName}<FaChevronRight/></h4>
                    <div className="home-featured-review-subtitle">{review.reviewReference.fields.reviewSubtitle}</div>
                    <div className="home-featured-review-authorship">
                        {authorData ? <div><IoPersonCircleSharp/> {authorData.firstName} {authorData.lastName}</div> : <></>}
                        {review.reviewReference.fields.containsSpoilers ? <div className="home-featured-review-spoilers-badge spoilers">Spoilers</div> : <div className="home-featured-review-spoilers-badge">Spoiler-Free</div> }
                    </div>
                </div>
            </div>
        </div>
    )
}
