import React, { useCallback, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import MovieSearchBar from '../moviesearchbar/MovieSearchBar';
import { TbListDetails } from "react-icons/tb";
import { useWatchlist } from '../../context/WatchlistContext';
import './Header.css';

function Logo({ onClick }) {
  return (
    <Link to="/" className="logo" onClick={onClick}>
      <div className="logo-text">HorrrorVerse</div>
    </Link>
  );
}

function DesktopHeader({ user, handleLogout, getProfileInitial, watchlistAnimationActive }) {
  const navigate = useNavigate();

  const handleProfileClick = () => {
    navigate('/profile');
  };

  const handleWatchlistClick = () => {
    navigate('/list');
  };

  const onSearch = (term) => {
    navigate(`/search?q=${encodeURIComponent(term)}`);
  };

  return (
    <nav className="desktop-nav">
      {user && (
        <>
          <div className={`desktop-watchlist-item ${watchlistAnimationActive ? 'animate-watchlist' : ''}`} onClick={handleWatchlistClick}>
            <TbListDetails className="desktop-watchlist-icon" title="Watchlist" />
          </div>
          <div>
            <span className="desktop-profile-icon" onClick={handleProfileClick}>{getProfileInitial()}</span>
          </div>
          <MovieSearchBar onSearch={onSearch} />
          <button onClick={handleLogout} className="desktop-auth-button desktop-logout-button">Log Out</button>
        </>
      )}
      {!user && <Link to="/login" className="desktop-auth-button desktop-auth-button-login">Sign In</Link>}
      {!user && <MovieSearchBar onSearch={onSearch} />}
    </nav>
  );
}

function Header() {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [watchlistAnimationActive, setWatchlistAnimationActive] = useState(false);
  const { triggerAnimation } = useWatchlist();

  const handleLogout = useCallback(async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Logout failed:', error.response?.data?.message || error.message);
    }
  }, [logout, navigate]);

  const getProfileInitial = useCallback(() => {
    return user && user.email ? user.email[0].toUpperCase() : '?';
  }, [user]);

  useEffect(() => {
    if (triggerAnimation) {
      setWatchlistAnimationActive(true);
      setTimeout(() => setWatchlistAnimationActive(false), 1000);
    }
  }, [triggerAnimation]);

  return (
    <header className="main-header">
      <div className="container">
        <Logo />
        <DesktopHeader 
          user={user} 
          handleLogout={handleLogout} 
          getProfileInitial={getProfileInitial}
          watchlistAnimationActive={watchlistAnimationActive}
        />
      </div>
    </header>
  );
}

export default Header;