import React from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import '../App.css';

const Profile = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Logout failed:', error);
      // Optionally, you can set an error state and display it to the user
    }
  };
  return (
    <div className="user-profile-container profile-page">
      
      <div className="user-profile-info">
        <div className="user-profile-account">
          <h3>Account Information</h3>
          <p>Email: {user.email}</p>
          
          <button className="user-profile-signout-button mobile-only" onClick={handleSignOut}>Sign Out</button>
        </div>
      </div>
    </div>
  );
};

export default Profile;